:root {
  --darkblue: #1f1f1f;
  --goldGradient: linear-gradient(45deg, #f9f295, #e0aa3e, #f9f295, #b88a44);
  --goldColor: #ffe44d;
}

@font-face {
  font-family: Belle;
  src: url('/fonts/Bellefair-Regular.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2 {
  font-family: Belle;
}

a {
  text-decoration: none;
}
html {
  font-size: 62.5%;
}

.areaSize {
  height: 100vh;
}

::-webkit-scrollbar {
  width: 2px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #dc5526;
  border-radius: 6px;
}

#contactPage {
  height: 100vh;
}

/* modal */

.container {
  max-width: 1200px;
  width: 800px;
  /* glass morph */
  background: rgba(0, 0, 0, 0.56);
  border-radius: 16px;
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border: 1px solid #f9f295;
  /* end of glass */
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 80vh;
  padding: 10px;
}
.container hr {
  border: none;
  border: 2px dashed #f9f295;
  width: 100%;
  height: 2px;
}
.container .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
}
.container .title,
.container .price {
  width: 100%;
  height: 80px;
}

.container .title h2 {
  font-size: 30px;
}
.container .desc {
  width: 100%;
  height: 300px;
  font-size: 25px;
}

.container .price {
  font-size: 20px;
}
.container .price strong {
  color: #f9f295;
  font-size: 25px;
}
@media screen and (max-width: 576.98px) {
  .container {
    width: 100%;
    height: auto;
    padding: 20px;
  }

  .container .close {
    position: absolute;
    top: 0;
    right: 20px;
    font-size: 50px;
  }

  .container .textArea {
    margin-top: 50px;
  }
}
