:root {
  --darkblue: #000;
  --orange: #dc5526;
}

@media screen and (max-width: 768px) {
  .slick-prev:before,
  .slick-next:before {
    color: #000;
    font-size: 8rem;
  }
}

@media screen and (max-width: 575.98px) {
  .slick-prev:before,
  .slick-next:before {
    color: #000;
    font-size: 5rem;
  }
  .slick-slider {
    height: 350px;
  }
  .slick-dots {
    width: 330px;
    bottom: -30px;
    right: 22px;
  }
}

@media screen and (max-width: 390.98px) {
  .slick-dots {
    width: 330px;
    bottom: -30px;
    right: 10px;
  }
}

.slick-prev:before,
.slick-next:before {
  color: #000;
  font-size: 5rem;
}

.slick-prev {
  left: -4%;
}
.slick-slide.slick-current {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.slick-dots {
  bottom: -5rem;
}
